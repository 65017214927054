export const MfIconsMap = {
  accordion: 'accordion.svg',
  add: 'add.svg',
  add_circle: 'add_circle.svg',
  arrow_down: 'arrow_down.svg',
  arrow_drop_down: 'arrow_drop_down.svg',
  arrow_left: 'arrow_left.svg',
  arrow_right: 'arrow_right.svg',
  arrow_up: 'arrow_up.svg',
  attachment: 'attachment.svg',
  calendar: 'today.svg',
  cart: 'cart.svg',
  check: 'check.svg',
  checklist: 'checklist.svg',
  chevron_down: 'chevron-down.svg',
  chevron_left: 'chevron-left.svg',
  chevron_right: 'chevron-right.svg',
  chevron_up: 'chevron-up.svg',
  close: 'close.svg',
  color: 'color.svg',
  column_layout: 'column_layout.svg',
  compress: 'compress.svg',
  container: 'container.svg',
  content_builder: 'content_builder.svg',
  content_copy: 'content_copy.svg',
  dataset: 'dataset.svg',
  dataset_linked: 'dataset_linked.svg',
  decrypt: 'decrypt.svg',
  delete: 'delete.svg',
  desktop: 'desktop.svg',
  devices_other: 'devices_other.svg',
  dot: 'dot.svg',
  double_check_green: 'double_check_green.svg',
  double_check_green_gray: 'double_check_green_gray.svg',
  double_file: 'double_file.svg',
  double_hand: 'double_hand.svg',
  download: 'download.svg',
  download_done: 'download_done.svg',
  drag_pan: 'drag_pan.svg',
  edit: 'edit_outlined.svg',
  edit_document: 'edit_document.svg',
  empty_page: 'empty_page.svg',
  error: 'error.svg',
  expand_less: 'expand_less.svg',
  expand_more: 'expand_more.svg',
  external_link: 'open_in_new.svg',
  eye_closed: 'visibility_off.svg',
  eye_open: 'visibility.svg',
  file: 'insert_drive_file.svg',
  file_upload: 'arrow_upward.svg',
  file_with_content: 'file_with_content.svg',
  format_bold: 'format_bold.svg',
  format_color_text: 'format_color_text.svg',
  format_italic: 'format_italic.svg',
  format_underlined: 'format_underlined.svg',
  fullscreen: 'fullscreen.svg',
  generate_file: 'generate-file.svg',
  grid: 'grid.svg',
  grid_off: 'grid_off.svg',
  hand: 'pan_tool.svg',
  headline: 'headline.svg',
  headset: 'headset_mic.svg',
  help: 'help.svg',
  iframe: 'iframe.svg',
  image: 'image.svg',
  image_search: 'image_search.svg',
  import: 'import.svg',
  info: 'info.svg',
  key: 'vpn_key.svg',
  line: 'line.svg',
  lock: 'lock.svg',
  mail: 'email.svg',
  menu: 'menu.svg',
  more_vert: 'more_vert.svg',
  move_down: 'move_down.svg',
  move_up: 'move_up.svg',
  no_chart_data: 'no_chart_data.svg',
  page_break: 'page_break.svg',
  pause: 'pause.svg',
  placeholder: 'placeholder.svg',
  qr_code: 'qr_code.svg',
  qr_code_arrow: 'qr_code_arrow.svg',
  redo: 'redo.svg',
  refresh: 'refresh.svg',
  remove: 'remove.svg',
  salutation: 'salutation.svg',
  search: 'search.svg',
  security: 'security.svg',
  signature: 'signature.svg',
  signatures: 'signatures.svg',
  smartphone: 'smartphone.svg',
  sort: 'sort.svg',
  star: 'star.svg',
  star_outline: 'star_outline.svg',
  stop: 'stop.svg',
  style: 'style.svg',
  success: 'check.svg',
  table: 'table.svg',
  tablet: 'tablet.svg',
  text: 'text.svg',
  toolbox: 'toolbox.svg',
  unarchive: 'unarchive.svg',
  under_construction: 'under-construction.svg',
  undo: 'undo.svg',
  unlock: 'lock_open.svg',
  update: 'update.svg',
  user: 'baseline-person.svg',
  user_outline: 'person-outline.svg',
  users: 'people_alt.svg',
  variable: 'variable.svg',
  waiting: 'waiting.svg',
  warning: 'warning.svg',
  youtube: 'youtube.svg',
  zoom_in: 'zoom_in.svg',
  zoom_out: 'zoom_out.svg',
};

export type MfIcon = keyof typeof MfIconsMap;
