<div *transloco="let t" class="form-input-wrap">
  @if (type === 'multiple') {
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }

    @if (showCheckAll) {
      <mat-selection-list class="checkboxes-list">
        <mat-list-option
          [disabled]="control.disabled"
          [disableRipple]="true"
          [selected]="selectedAll"
          (click)="toggleAll()"
          checkboxPosition="before"
          color="primary"
        >
          <div class="flex items-center space-x-2">
            <span>{{ checkAllLabel || t('SHARED.FORMS.SELECT_ALL') }}</span>
          </div>
        </mat-list-option>
        <div class="ml-7">
          <ng-container [ngTemplateOutlet]="multipleCheckboxes" />
        </div>
      </mat-selection-list>
    } @else {
      <mat-selection-list class="checkboxes-list" [formControl]="$any(control)" [ngClass]="name">
        @for (item of items; track item.value; let i = $index) {
          <mat-list-option
            [disabled]="!!item.disabled || control.disabled"
            [disableRipple]="true"
            [value]="item.value"
            checkboxPosition="before"
            color="primary"
          >
            <div class="flex items-center space-x-2">
              <span [innerHTML]="item.label"></span>
              @if (item.info) {
                <mf-info-hover [text]="item.info.text" [title]="item.info.title" />
              }
            </div>
            @if (item.subLabel) {
              <br />
              <span [innerHTML]="item.subLabel"></span>
            }
          </mat-list-option>
        }
      </mat-selection-list>
    }

    <ng-template #multipleCheckboxes>
      <mat-selection-list class="checkboxes-list" [formControl]="$any(control)" [ngClass]="name">
        @for (item of items; track item.value; let i = $index) {
          <mat-list-option
            [disabled]="!!item.disabled || control.disabled"
            [disableRipple]="true"
            [value]="item.value"
            checkboxPosition="before"
            color="primary"
          >
            <div class="flex items-center space-x-2">
              <span [innerHTML]="item.label"></span>
              @if (item.info) {
                <mf-info-hover [text]="item.info.text" [title]="item.info.title" />
              }
            </div>
            @if (item.subLabel) {
              <br />
              <span [innerHTML]="item.subLabel"></span>
            }
          </mat-list-option>
        }
      </mat-selection-list>
    </ng-template>
  }

  @if (type === 'single') {
    <mat-checkbox
      [disableRipple]="true"
      [formControl]="$any(control)"
      [ngClass]="name"
      color="primary"
    >
      <div class="flex items-center space-x-1">
        <span [class.whitespace-normal]="multilineLabel" [innerHTML]="label"></span>
        @if (labelHint) {
          <mf-info-hover [text]="labelHint" [title]="labelHintTitle" />
        }
      </div>
    </mat-checkbox>
  }

  @if (!hideHintErrorArea) {
    <div class="custom-hint-error-container mt-2">
      <mat-hint>
        <mf-form-util-hint-handler
          [field]="control"
          [hideInfoIcon]="hideInfoIcon"
          [hintLabel]="hintLabel"
          [isRequired]="isRequired"
          [patternName]="patternName"
          [showOptionalHint]="showOptionalHint"
          [showValidationSuccess]="showValidationSuccess"
        />
      </mat-hint>

      <mat-error [hidden]="hideErrorMessage">
        <mf-form-util-error-handler
          [field]="control"
          [patternName]="patternName"
          [translationPrefix]="translationPrefix"
          [translationPrefixScope]="translationPrefixScope"
        />
      </mat-error>
    </div>
  }
</div>
