@if (shouldShowError) {
  <div class="flex items-center justify-between space-x-1">
    <div>
      @if (firstError; as error) {
        <span class="flex items-center space-x-1">
          <mat-icon svgIcon="error" />
          <span [innerHTML]="getErrorLabel(error)"></span>
        </span>
      }
    </div>
    @if (maxLengthHint) {
      <mf-form-util-length-handler [field]="field" [maxLength]="maxLengthHint" />
    }
  </div>
}
