import { Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MfFormHtmlInputComponent } from '@app/form/field/html-input/html-input.component';
import { MfFormImageInputComponent } from '@app/form/field/image-input/image-input.component';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { CustomValidators } from '@app/form/field/shared/validators/custom-validator';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import {
  MfInlineEditSignatureDialogData,
  MfInlineEditSignatureDialogResult,
} from '@shared/dialog/inline-edit-signature-dialog/inline-edit-signature-dialog.interface';
import { MfAbstractDialogInjected } from '@shared/dialog/service/dialog.interface';

@Component({
  selector: 'mf-inline-edit-signature-dialog',
  templateUrl: './inline-edit-signature-dialog.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    MaterialModule,
    MfTertiaryButtonComponent,
    MfPrimaryButtonComponent,
    MfFormFocusFirstInvalidFieldDirective,
    forwardRef(() => MfFormHtmlInputComponent),
    MfFormImageInputComponent,
  ],
})
export class MfInlineEditSignatureDialogComponent extends MfAbstractDialogInjected<
  MfInlineEditSignatureDialogData,
  MfInlineEditSignatureDialogResult
> {
  public readonly form = this.createForm();

  createForm() {
    return new FormGroup({
      signature: new FormControl(this.data.signature ?? '', {
        nonNullable: true,
        validators: [...(this.data.optional ? [] : [Validators.required])],
      }),
      signatureFilename: new FormControl(this.data.signatureFilename ?? '', {
        nonNullable: true,
        ...(this.data.optional ? [] : [Validators.required]),
      }),
      signatureAddition: new FormControl(this.data.signatureAddition ?? '', {
        nonNullable: true,
        validators: [
          CustomValidators.maxParagraphTagsInHtml(2),
          ...(this.data.optional ? [] : [Validators.required]),
        ],
      }),
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value as MfInlineEditSignatureDialogResult);
  }
}
