import { HttpHeaders, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MfAuthenticationService } from '@app/shared/authentication/authentication.service';
import { MfHttpErrorResponse } from '@app/shared/data/error.interface';
import { Notification } from '@app/shared/notification/notification.model';
import { MfNotificationService } from '@app/shared/notification/notification.service';
import { environment } from '@env/environment';
import { MfDialogService } from '@shared/dialog/service/dialog.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class MfHttpInterceptor {
  private static handleInterception<T>(request: HttpRequest<T>): HttpRequest<T> {
    const url: string = environment.serverUrl;

    const token = MfAuthenticationService.getAccessToken();

    const headers: HttpHeaders = request.headers.append(
      'Authorization',
      token ? 'Bearer ' + token : ''
    );

    if (!headers.has('Content-Type')) {
      headers.append('Accept', 'application/json').append('Content-Type', 'application/json');
    }

    return request.clone({
      headers,
      url: url + request.url,
    });
  }

  static interceptorFn: HttpInterceptorFn = (request, next) => {
    const mfAuthenticationService = inject(MfAuthenticationService);
    const mfNotificationService = inject(MfNotificationService);
    const mfDialogService = inject(MfDialogService);

    if (request.responseType === 'text' || request.url.includes('assets/')) {
      return next(request);
    }

    const interceptedRequest = MfHttpInterceptor.handleInterception(request);

    return next(interceptedRequest).pipe(
      catchError((error: MfHttpErrorResponse) => {
        if (error.status === 0) {
          mfNotificationService.send(
            Notification.error(
              'SHARED.ERROR.NO_CONNECTION.MESSAGE',
              'SHARED.ERROR.NO_CONNECTION.TITLE'
            )
          );
        }
        if (error.status === 401) {
          if (
            error.error?.message === 'JWT Token not found' ||
            error.error?.message === 'Expired JWT Token'
          ) {
            mfNotificationService.send(Notification.info('SHARED.INFO.SESSION_EXPIRED'));
            mfDialogService.closeAll();

            mfAuthenticationService.logout();
          }
        }

        return throwError(() => error);
      })
    );
  };
}
