<ng-container *transloco="let t; scope: 'dataTransfer'">
  @if (isLoggedInAsDataManager) {
    <div class="widget-container flex flex-col space-y-4">
      @if (showExportWidget) {
        <div class="export-widget-container" [@fade-toggle]="showExportWidget">
          <mf-data-manager-export-downloader isWidget />
        </div>
      }
      @if (showImportWidget) {
        <div class="import-widget-container" [@fade-toggle]="showImportWidget">
          @switch (uploaderService.importStep | async) {
            @case (MfDataManagerImportStepEnum.UPLOADING) {
              <mf-data-manager-card icon="file_upload" isWidget>
                <p class="font-bold">
                  {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.PROGRESS_TITLE') }}
                </p>
                <div>
                  <p class="mb-2">
                    @if (!uploadState?.progress) {
                      <span>{{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.PREPARE_UPLOAD') }}</span>
                    }
                    @if (uploadState && uploadState.progress > 0 && uploadState.progress < 100) {
                      <span>{{ uploadState.loadedBytes | fileSize }}&thinsp;</span>
                      <span>{{ t('SHARED.GENERAL.OF') | lowercase }}&thinsp;</span>
                      <span>{{ uploadState.totalBytes | fileSize }}</span>
                      <span>&nbsp;</span>
                      <span>({{ uploadState.progress }}%)</span>
                      <br />
                      <span>{{ uploadState.uploadTimeEstimate | uploadTimeEstimate }}</span>
                    }
                    @if (uploadState?.progress === 100) {
                      <span>{{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.FOLLOW_UP_UPLOAD') }}</span>
                    }
                  </p>
                  <mat-progress-bar
                    class="upload-progress-bar used-in-widget"
                    [value]="uploadState?.progress"
                    mode="determinate"
                  />
                </div>
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.UPLOAD_ERROR) {
              <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
                <p class="mb-1 font-bold">
                  @switch (uploadState?.responseStatus) {
                    @case (403) {
                      <mat-error>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.NETWORK_PROBLEM.TITLE_WIDGET'
                          )
                        }}
                      </mat-error>
                    }
                    @default {
                      <mat-error>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_CONTENT.TITLE_WIDGET'
                          )
                        }}
                      </mat-error>
                    }
                  }
                </p>
                <p>
                  @switch (uploadState?.responseStatus) {
                    @case (403) {
                      <mat-error>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.NETWORK_PROBLEM.MESSAGE'
                          )
                        }}
                      </mat-error>
                    }
                    @default {
                      <mat-error>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_CONTENT.MESSAGE'
                          )
                        }}
                      </mat-error>
                    }
                  }
                </p>
                <div class="mt-2">
                  <div class="flex space-x-4">
                    @if (!isOnImportPage) {
                      <span class="link" routerLink="/datentransfer/datenimport">
                        {{ t('dataTransfer.IMPORT.WIDGET.ACTIONS.GO_TO_UPLOAD_PAGE') }}
                      </span>
                    }
                    <span class="link" (click)="showImportWidget = false">
                      {{ t('SHARED.GENERAL.DATE_TIME.LATER') | titlecase }}
                    </span>
                  </div>
                </div>
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.NEEDS_PASSWORDS) {
              <mf-data-manager-card isWidget>
                <p class="mb-1 px-3 font-bold">
                  <span>{{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.TITLE') }}</span>
                </p>
                @if (isOnImportPage) {
                  <p class="px-3">
                    <span>
                      {{
                        t(
                          'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.ENTER_PASSWORD.' +
                            MfDataManagerImportUtil.getEncryptedTranslationKey(
                              uploadedImport?.encrypted
                            )
                        )
                      }}
                    </span>
                  </p>
                } @else {
                  <p class="px-3">
                    @if (!passwordCollapsed) {
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.' +
                              MfDataManagerImportUtil.getEncryptedTranslationKey(
                                uploadedImport?.encrypted
                              ) +
                              '.INITIAL'
                          )
                        }}
                      </span>
                      <span class="link" (click)="passwordCollapsed = !passwordCollapsed">
                        {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_2') }}
                      </span>
                      <span>
                        {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_3') }}
                      </span>
                      <a class="link" routerLink="/datentransfer/datenimport">
                        <span>
                          {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                        </span>
                      </a>
                      <span>
                        {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_5') }}
                      </span>
                    }
                    @if (passwordCollapsed) {
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.' +
                              MfDataManagerImportUtil.getEncryptedTranslationKey(
                                uploadedImport?.encrypted
                              ) +
                              '.COLLAPSED'
                          )
                        }}
                      </span>
                      <a class="link" routerLink="/datentransfer/datenimport">
                        <span>
                          {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                        </span>
                      </a>
                      <span>
                        {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_5') }}
                      </span>
                    }
                  </p>
                }
                @if (passwordCollapsed && !isOnImportPage) {
                  <div
                    class="has-divider mt-3 px-3 pt-4"
                    [@fade-toggle]="passwordCollapsed && !isOnImportPage"
                  >
                    <mf-data-manager-import-uploader-password
                      [primaryActionLabel]="
                        t('dataTransfer.IMPORT.UPLOAD_CARD.ACTIONS.CONTINUE_IMPORT')
                      "
                      [showDecryptPassword]="uploadedImport?.encrypted"
                      (cancelPatch)="uploaderService.cancelImport()"
                      (submitPatch)="uploaderService.submitPasswords($event)"
                      isUsedInWidget
                      showPseudonymizationPassword
                    />
                  </div>
                }
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.SUBMITTING_PASSWORDS) {
              <mf-data-manager-card iconAnimated isWidget>
                <p>
                  {{
                    t(
                      'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.CHECKING_PASSWORD.' +
                        MfDataManagerImportUtil.getEncryptedTranslationKey(
                          uploadedImport?.encrypted
                        )
                    )
                  }}
                </p>
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.ENCRYPTION_PASSWORD_WRONG) {
              <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
                <p class="mb-1 font-bold">
                  <mat-error>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.TITLE'
                      )
                    }}
                  </mat-error>
                </p>
                @if (isOnImportPage) {
                  <p>
                    <span>
                      {{
                        t(
                          'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.MESSAGE'
                        )
                      }}
                    </span>
                  </p>
                } @else {
                  <p class="mb-4">
                    <mat-error>
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.MESSAGE_1'
                          )
                        }}
                      </span>
                      <a class="link" routerLink="/datentransfer/datenimport">
                        <span>
                          {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                        </span>
                      </a>
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.MESSAGE_2'
                          )
                        }}
                      </span>
                    </mat-error>
                  </p>
                  <mf-data-manager-import-uploader-password
                    [primaryActionLabel]="t('SHARED.GENERAL.CONTINUE')"
                    (cancelPatch)="uploaderService.cancelImport()"
                    (submitPatch)="uploaderService.submitPasswords($event)"
                    isUsedInWidget
                    showDecryptPassword
                  />
                }
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.PSEUDONYMIZATION_PASSWORD_WRONG) {
              <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
                <p class="mb-1 font-bold">
                  <mat-error>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.TITLE'
                      )
                    }}
                  </mat-error>
                </p>
                @if (isOnImportPage) {
                  <p>
                    <span>
                      {{
                        t(
                          'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.MESSAGE'
                        )
                      }}
                    </span>
                  </p>
                } @else {
                  <p class="mb-4">
                    <mat-error>
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.MESSAGE_1'
                          )
                        }}
                      </span>
                      <a class="link" routerLink="/datentransfer/datenimport">
                        <span>
                          {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                        </span>
                      </a>
                      <span>
                        {{
                          t(
                            'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.MESSAGE_2'
                          )
                        }}
                      </span>
                    </mat-error>
                  </p>
                  <mf-data-manager-import-uploader-password
                    [primaryActionLabel]="t('SHARED.GENERAL.CONTINUE')"
                    (cancelPatch)="uploaderService.cancelImport()"
                    (submitPatch)="uploaderService.submitPasswords($event)"
                    isUsedInWidget
                    showPseudonymizationPassword
                  />
                }
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.UNKNOWN_ERROR) {
              <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
                <p class="mb-1 font-bold">
                  <mat-error>
                    {{
                      t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.ERROR.TITLE_WIDGET')
                    }}
                  </mat-error>
                </p>
                <p class="mb-4">
                  <mat-error>
                    {{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.ERROR.MESSAGE') }}
                  </mat-error>
                </p>
                <mf-secondary-button
                  [label]="t('dataTransfer.IMPORT.UPLOAD_CARD.ACTIONS.CANCEL_IMPORT')"
                  (clicked)="uploaderService.cancelImport()"
                  autoStretch
                />
              </mf-data-manager-card>
            }
            @case (MfDataManagerImportStepEnum.FINISHED) {
              <mf-data-manager-card class="has-success" icon="success" isWidget>
                <p>
                  {{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_SUCCESS') }}
                </p>
              </mf-data-manager-card>
            }
          }
        </div>
      }
    </div>
  }
</ng-container>
