import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';

@Component({
  selector: 'mf-info-hover',
  templateUrl: './info-hover.component.html',
  styleUrls: ['./info-hover.component.scss'],
  standalone: true,
  imports: [MaterialModule],
})
export class MfInfoHoverComponent
  extends MfUnsubscribeComponent
  implements AfterViewInit, OnDestroy
{
  @Input() title?: string;
  @Input() text?: string;

  @ViewChild('content', { static: true }) private hintContent?: TemplateRef<any>;
  @ViewChild('overlayToggle', { static: true }) private overlayToggle?: ElementRef;

  private overlayRef!: OverlayRef;
  private openedByClick: boolean = false;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.createOverlay();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.detachOverlay();
  }

  private createOverlay(): void {
    if (!this.overlayToggle) {
      throw Error('Could not find overlayToggle element!');
    }

    const scrollStrategy = this.overlay.scrollStrategies.close();

    const topRightPosition = new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' }
    );
    topRightPosition.panelClass = 'top-right';
    topRightPosition.offsetY = -8;

    const topLeftPosition = new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'end', overlayY: 'top' }
    );
    topLeftPosition.panelClass = 'top-left';
    topLeftPosition.offsetX = -16;
    topRightPosition.offsetY = -8;

    const aboveCenterPosition = new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' }
    );
    aboveCenterPosition.panelClass = 'above-center';

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.overlayToggle)
      .withPositions([topRightPosition, topLeftPosition, aboveCenterPosition])
      .withViewportMargin(16)
      .withPush(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      disposeOnNavigation: true,
      panelClass: 'info-hover-panel',
    });

    this.overlayRef
      .outsidePointerEvents()
      .pipe(this.takeUntilUnsubscribe())
      .subscribe((event) => {
        if (this.openedByClick) {
          event.stopPropagation();
          this.openedByClick = false;
          this.detachOverlay();
        }
      });

    this.overlayRef
      .detachments()
      .pipe(this.takeUntilUnsubscribe())
      .subscribe(() => {
        this.openedByClick = false;
      });
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached() && this.hintContent) {
      const portal = new TemplatePortal(this.hintContent, this.viewContainerRef);

      this.overlayRef.attach(portal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  handleMouseEnter() {
    if (!this.openedByClick) {
      this.attachOverlay();
    }
  }

  handleMouseLeave() {
    if (!this.openedByClick) {
      this.detachOverlay();
    }
  }

  handleClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.openedByClick && this.overlayRef.hasAttached()) {
      this.openedByClick = false;
      this.detachOverlay();
    } else {
      this.openedByClick = true;
      this.attachOverlay();
    }
  }
}
