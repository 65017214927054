import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { MaterialModule } from '@app/material/material.module';
import { Notification, NotificationEnum } from '@app/shared/notification/notification.model';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'mf-notification-content',
  templateUrl: './content.component.html',
  standalone: true,
  imports: [MaterialModule, TranslocoDirective, NgClass],
})
export class MfNotificationContentComponent {
  readonly NotificationEnum = NotificationEnum;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public readonly data: { notification: Notification; translationScope?: string },
    private matSnackBarRef: MatSnackBarRef<MfNotificationContentComponent>
  ) {}

  dismiss(): void {
    this.matSnackBarRef.dismiss();
  }
}
