<ng-container *transloco="let t">
  <div class="grid grid-cols-2">
    <a class="link-item" [ngClass]="!isMarketplace ? 'active' : 'inactive'" [routerLink]="'/'">
      {{ t('SIDENAV.NAVIGATION.TABS.MARKETINGFABRIK') }}
    </a>
    <a
      class="link-item"
      [ngClass]="isMarketplace ? 'active' : 'inactive'"
      [routerLink]="'/marktplatz/redirect'"
    >
      {{ t('SIDENAV.NAVIGATION.TABS.MARKETPLACE') }}
      @if (isNew) {
        <!-- TODO: Remove after 2025-03-01 -->
        <span class="h-4 rounded-lg bg-[#B8E5ED] px-1 text-xs font-bold">Neu</span>
      }
    </a>
  </div>
</ng-container>
