<h1 class="mat-headline mb-4" mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  @if (data.message) {
    <p class="mb-4" [innerHTML]="message"></p>
  }

  @if (list) {
    <ul class="dialog-list">
      @for (item of list; track item) {
        <li>{{ item }}</li>
      }
    </ul>
  }

  @if (isLoadingList) {
    <div class="py-1">
      <mat-progress-spinner color="primary" diameter="18" mode="indeterminate" />
    </div>
  }

  @if (data.doNotShowAgainKey) {
    <ng-container *transloco="let t">
      <mf-form-checkbox
        [control]="doNotShowAgainControl"
        [label]="t('DO_NOT_SHOW_AGAIN')"
        type="single"
      />
    </ng-container>
  }
</div>
<div class="styled" mat-dialog-actions>
  @if (data.action.secondary) {
    <mf-tertiary-button
      [disabled]="isSubmitting"
      [label]="data.action.secondary.label"
      (clicked)="clickedSecondaryAction()"
    />
  }

  @if (data.action.primary) {
    @if (!data.options?.usePrimaryButton) {
      <mf-tertiary-button
        [hasInitialFocus]="!data.noInitialFocus"
        [isLoading]="isSubmitting"
        [label]="data.action.primary.label"
        (clicked)="clickedPrimaryAction()"
      />
    }

    @if (data.options?.usePrimaryButton) {
      <mf-primary-button
        [hasInitialFocus]="!data.noInitialFocus"
        [isLoading]="isSubmitting"
        [label]="data.action.primary.label"
        (clicked)="clickedPrimaryAction()"
      />
    }
  }
</div>
