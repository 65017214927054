import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { MfAuthenticationService } from '@app/shared/authentication/authentication.service';
import { MfUserEventService } from '@app/shared/authentication/user/user-event.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'mf-layout-sidenav-user-actions',
  templateUrl: './user-actions.component.html',
  standalone: true,
  imports: [MaterialModule, TranslocoDirective, RouterLink, RouterLinkActive],
})
export class MfLayoutSidenavUserActionsComponent {
  @Input() userName!: string;
  @Input() entityName!: string;

  constructor(
    private authService: MfAuthenticationService,
    private userEventService: MfUserEventService
  ) {}

  public logout(): void {
    this.userEventService.triggerLogout();
    this.authService.logout();
  }
}
