<ng-container *transloco="let t">
  @for (item of items; track $index) {
    @if (!$first) {
      <mat-icon svgIcon="chevron_right" />
    }

    @if (item.link && !$last) {
      <a class="crumb" [routerLink]="item.link">
        {{ t(item.title) }}
      </a>
    } @else {
      <span class="crumb">
        {{ t(item.title) }}
      </span>
    }
  }
</ng-container>
