import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MfFormCheckboxComponent } from '@app/form/field/checkbox/checkbox.component';
import { MaterialModule } from '@app/material/material.module';
import { MfTertiaryButtonComponent } from '@app/shared/components/buttons/tertiary/tertiary.component';
import { MfAbstractDialogInjected } from '@app/shared/dialog/service/dialog.interface';
import { MfUserCustomizationService } from '@app/shared/user-customization/user-customization.service';
import { TranslocoModule } from '@jsverse/transloco';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { Observable } from 'rxjs';
import { MfConfirmDialogInterface } from './confirm-dialog.interface';

@Component({
  selector: 'mf-dialog',
  templateUrl: './confirm-dialog.component.html',
  standalone: true,
  imports: [
    MaterialModule,
    MfTertiaryButtonComponent,
    TranslocoModule,
    MfFormCheckboxComponent,
    MfPrimaryButtonComponent,
  ],
})
export class MfConfirmDialogComponent extends MfAbstractDialogInjected<
  MfConfirmDialogInterface,
  string
> {
  public isLoadingList: boolean = false;
  public list: string[] = [];
  public doNotShowAgainControl = new FormControl(false);
  public isSubmitting: boolean = false;

  constructor(
    private userCustomizationService: MfUserCustomizationService,
    private sanitizer: DomSanitizer
  ) {
    super();

    this.initList();
  }

  get message() {
    const message = this.data.message.replace(/\n/g, '<br>');

    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  public clickedPrimaryAction() {
    // Check if "do not show again" is active and update preference
    if (this.data.doNotShowAgainKey && this.doNotShowAgainControl.value) {
      this.userCustomizationService.enableDoNotShowAgain(this.data.doNotShowAgainKey);
    }

    this.data.action.primary?.action
      ? this.data.action.primary.action(this.dialogRef)
      : this.close(this.data.action.primary!.value);
  }

  public clickedSecondaryAction() {
    // Check if "do not show again" is active and update preference
    if (this.data.doNotShowAgainKey && this.doNotShowAgainControl.value) {
      this.userCustomizationService.enableDoNotShowAgain(this.data.doNotShowAgainKey);
    }

    this.data.action.secondary?.action
      ? this.data.action.secondary.action(this.dialogRef)
      : this.close(this.data.action.secondary!.value);
  }

  initList(): void {
    if (!this.data.list) {
      return;
    }

    if (this.data.list instanceof Observable) {
      this.isLoadingList = true;
      this.data.list.subscribe((list) => {
        this.isLoadingList = false;
        this.list = list;
      });
    } else {
      this.list = this.data.list;
    }
  }
}
