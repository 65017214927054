import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';
import { MfBreadcrumbItem } from '@app/shared/breadcrumbs/breadcrumbs-item.model';
import { MfBreadcrumbsService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, MaterialModule, RouterLink],
  standalone: true,
})
export class MfBreadcrumbsComponent extends MfUnsubscribeComponent implements OnInit {
  private breadcrumbsService = inject(MfBreadcrumbsService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  public items: MfBreadcrumbItem[] = [];

  ngOnInit(): void {
    this.breadcrumbsService.update$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
      this.items = items;

      this.cd.detectChanges();
    });
  }
}
