<ng-container *transloco="let t">
  <div class="mf-logo-wrapper hidden items-center justify-center xl:flex">
    <a [routerLink]="redirectUrl()">
      <img class="h-6" [attr.alt]="t(logoTitle)" [src]="logo" />
    </a>
  </div>

  <div class="flex h-full min-h-0 flex-col">
    @if (hasTabs) {
      <mf-layout-sidenav-tabs />
    }
    <mf-layout-sidenav-content class="flex-auto" />
  </div>

  <mf-layout-sidenav-user-actions [entityName]="entityName" [userName]="userName" />
</ng-container>
