<ng-container *transloco="let t">
  <div class="flex items-center">
    <div class="mr-4 xl:hidden">
      @if (userService.isLoggedIn()) {
        <button (click)="clicked()" #menuButton mat-icon-button type="button">
          <mat-icon aria-hidden="false" aria-label="Menu" svgIcon="menu" />
        </button>
      }
    </div>

    <div class="flex w-full items-center justify-between xl:hidden">
      <div>
        @if (isSticky) {
          <mf-breadcrumbs />
        }
      </div>

      <a [routerLink]="redirectUrl()">
        <img class="mf-logo" [attr.alt]="t(logoTitle)" [height]="24" [src]="logo" [width]="220" />
      </a>
    </div>
  </div>
</ng-container>
