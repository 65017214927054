@if (control) {
  <mat-form-field
    [class.no-bottom-offset]="noBottomOffset"
    [class.no-label]="noLabel"
    [class.simple-suffix]="suffix"
    [hideRequiredMarker]="hideAsterisk"
    [ngClass]="[name]"
  >
    <mat-label>{{ label }}</mat-label>

    <input
      [autocomplete]="autocomplete"
      [formControl]="$any(control)"
      [max]="max"
      [min]="min"
      [name]="name"
      [ngClass]="{ 'hide-number-spinner': hideNumberSpinner }"
      [placeholder]="placeholder || ''"
      [required]="isRequired"
      [type]="type"
      #passwordInput
      matInput
    />
    @if (control.pending) {
      <mat-spinner [diameter]="24" matSuffix />
    }

    @if (suffix) {
      <span class="pb-2" matSuffix>{{ suffix }}</span>
    }

    @if (passwordHasToggleButton && !control.pending) {
      <button (click)="toggleShowPassword()" mat-icon-button matSuffix type="button">
        <mat-icon [svgIcon]="type === 'password' ? 'eye_closed' : 'eye_open'" id="input-eye-icon" />
      </button>
    }

    <mat-hint>
      @if (!multipleHintsTemplate) {
        <mf-form-util-hint-handler
          [field]="control"
          [hideInfoIcon]="hideInfoIcon"
          [hintLabel]="hintLabel"
          [isRequired]="isRequired"
          [maxLengthHint]="maxLengthHint!"
          [patternName]="patternName"
          [showOptionalHint]="showOptionalHint"
          [showValidationSuccess]="showValidationSuccess"
        />
      } @else {
        <mf-form-util-multi-hint-handler [field]="control" [type]="multipleHintsTemplate!" />
      }
    </mat-hint>

    @if (!hideErrorMessage) {
      <mat-error>
        <mf-form-util-error-handler
          [field]="control"
          [maxLengthHint]="maxLengthHint!"
          [patternName]="patternName"
          [translationPrefix]="translationPrefix"
          [translationPrefixScope]="translationPrefixScope"
        />
      </mat-error>
    }
  </mat-form-field>
}
