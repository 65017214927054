<ng-container *transloco="let t">
  @if (control) {
    <mat-form-field
      [hideRequiredMarker]="hideAsterisk"
      [ngClass]="[name, noBottomOffset ? 'no-bottom-offset' : '', noLabel ? 'no-label' : '']"
    >
      @if (!noLabel) {
        <mat-label>{{ label }}</mat-label>
      }

      <ng-select
        [addTag]="addTag"
        [addTagText]="addTagText"
        [appendTo]="appendTo!"
        [bindValue]="bindValue!"
        [clearable]="(clearable && control.value) || type === 'multiple'"
        [clearAllText]="t('SHARED.FORMS.SELECT.CLEAR_ALL_TEXT')"
        [clearSearchOnAdd]="!!addTag"
        [closeOnSelect]="type !== 'multiple'"
        [dropdownPosition]="dropdownPosition"
        [formControl]="$any(control)"
        [groupBy]="groupByFn"
        [items]="items"
        [loading]="showLoadingWhileNull && items === null"
        [loadingText]="t('SHARED.FORMS.SELECT.LOADING')"
        [multiple]="type === 'multiple'"
        [ngClass]="[
          name,
          isVirtualScrollEnabled ? '' : 'no-virtual-scroll',
          hasSublabels ? 'has-sublabels' : '',
        ]"
        [notFoundText]="notFoundText ? notFoundText : t('SHARED.FORMS.SELECT.NO_ITEMS_FOUND')"
        [placeholder]="placeholder || ''"
        [required]="isRequired"
        [searchFn]="searchFn()"
        [virtualScroll]="isVirtualScrollEnabled"
        (close)="onClose()"
        (ngModelChange)="selectionChangedValue($event)"
        (search)="onTyping($event)"
        appearance="outline"
        bindLabel="label"
        ngSelectMat
      >
        @if (displayAddAllItemsButton) {
          <ng-template ng-header-tmp>
            <button
              class="add-all-items-buttom"
              (click)="addSearchResultItemsToControl()"
              type="button"
            >
              {{ t('SHARED.FORMS.SELECT.ADD_ALL_ITEMS') }}
            </button>
          </ng-template>
        }
        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          {{ getItemDisplayLabel(item) }}
          @if (item.sublabel) {
            <span class="sublabel">{{ item.sublabel }}</span>
          }
        </ng-template>
      </ng-select>

      <mat-hint>
        <mf-form-util-hint-handler
          [field]="control"
          [hideInfoIcon]="hideInfoIcon"
          [hintLabel]="hintLabel"
          [isRequired]="isRequired"
          [maxLengthHint]="maxLengthHint"
          [patternName]="patternName"
          [showOptionalHint]="showOptionalHint"
        />
      </mat-hint>

      @if (!hideErrorMessage) {
        <mat-error>
          @if (control) {
            <mf-form-util-error-handler
              [field]="control"
              [patternName]="patternName"
              [translationPrefix]="translationPrefix"
              [translationPrefixScope]="translationPrefixScope"
            />
          }
        </mat-error>
      }
    </mat-form-field>
  }
</ng-container>
