<button
  class="btn-tertiary"
  [attr.cdkFocusInitial]="hasInitialFocus ? '' : null"
  [attr.type]="type"
  [color]="'primary'"
  [disabled]="disabled || isLoading"
  [ngClass]="{
    'auto-stretch': autoStretch,
    small: small,
  }"
  (click)="onClicked($event)"
  mat-stroked-button
>
  <span class="flex items-center justify-center" [ngStyle]="{ opacity: isLoading ? 0 : 1 }">
    @if (icon && iconPosition === 'before') {
      <mat-icon class="mr-1" [svgIcon]="icon" />
    }
    @if (label) {
      <span [ngStyle]="{ 'padding-top.px': icon ? 1 : 0 }">{{ label }}</span>
    }
    @if (icon && iconPosition === 'after') {
      <mat-icon class="ml-1" [svgIcon]="icon" />
    }
    @if (icon && iconPosition === 'alone') {
      <mat-icon class="py-1" [svgIcon]="icon" />
    }
  </span>
  @if (isLoading) {
    <span class="is-spinner-inside-button">
      <mat-progress-spinner [color]="'primary'" [diameter]="18" [mode]="'indeterminate'" />
    </span>
  }
</button>
