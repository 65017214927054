import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { BankFeatures } from '@app/shared/bank-customization/bank-customization.type';
import { MfSidenavTopLevelItemInterface } from '@app/shared/navigation/navigation.types';

export const MF_NAVIGATION: MfSidenavTopLevelItemInterface[] = [
  {
    title: 'SIDENAV.NAVIGATION.CAMPAIGN.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.FINANCE_HOUSE',
        visibleForRoles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
        link: '/finanzhaus',
      },
      {
        title: 'SIDENAV.NAVIGATION.CAMPAIGN.TITLE',
        link: '/kampagnen',
        visibleForRoles: [
          MfUserRolesEnum.CAMPAIGN_MANAGER,
          MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
        ],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.CAMPAIGN.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.CAMPAIGN.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.CONSENT_ACTION.TITLE',
    visibleForRoles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.BACK_OFFICE],
    requiredBankFeatures: [BankFeatures.CONSENT_ACTION],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.CONSENT_ACTION.OVERVIEW',
        link: '/zustimmungsaktionen',
        children: [
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_GROUP.TITLE',
        link: '/zustimmungsaktionen-gruppen',
        children: [
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_GROUP.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_GROUP.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_LANDING_PAGE.TITLE',
        link: '/zustimmungsseiten',
        children: [
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_LANDING_PAGE.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.CONSENT_ACTION_LANDING_PAGE.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.DOCUMENT_ARCHIVE',
        link: '/dokumentenarchiv',
      },
      {
        title: 'SIDENAV.NAVIGATION.DOCUMENT_FORM_SCAN.TITLE',
        link: '/marktfolge',
        visibleForRoles: [MfUserRolesEnum.BACK_OFFICE],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.SELECTION.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.SELECTION.EXCLUSION.OVERVIEW',
        link: '/ausschluesse',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.SELECTION.EXCLUSION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.SELECTION.EXCLUSION.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.SELECTION_CHECKLIST',
        link: '/prueflisten',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
      },
      {
        title: 'SIDENAV.NAVIGATION.SELECTION.INCLUSION.OVERVIEW',
        link: '/selektionen',
        visibleForRoles: [
          MfUserRolesEnum.CAMPAIGN_MANAGER,
          MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
          MfUserRolesEnum.DATA_MANAGER,
          MfUserRolesEnum.PARTNER_DATA_MANAGER,
        ],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.SELECTION.INCLUSION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.SELECTION.INCLUSION.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.TARGET_GROUP.TITLE',
        link: '/zielgruppen',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.TARGET_GROUP.IMPORT',
            link: '/anlegen/importieren',
          },
          {
            title: 'SIDENAV.NAVIGATION.TARGET_GROUP.CONNECT_ANALYSIS',
            link: '/anlegen/externe-analyse',
            requiredBankFeatures: [
              BankFeatures.TARGET_GROUP_MITTELSTAND_AI,
              BankFeatures.TARGET_GROUP_BANKINGONE_AI,
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.SERVICE_PROCESS.TITLE',
    visibleForRoles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
    requiredBankFeatures: [
      BankFeatures.EMAIL_APPOINTMENT_REMINDER,
      BankFeatures.CONSENT_ACTION_COLLECT_MISSING_EMAILS,
      BankFeatures.CONSENT_ACTION_COLLECT_MISSING_EPOST,
    ],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.SERVICE_PROCESS.TITLE',
        link: '/serviceprozesse',
        children: [
          {
            title: 'SIDENAV.NAVIGATION.SERVICE_PROCESS.EDIT_APPOINTMENT_REMINDER',
            link: '/terminerinnerung/email',
          },
        ],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.TITLE',
    visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.IMPORT',
        link: '/datentransfer/datenimport',
      },
      {
        title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.IMPORT_HISTORY',
        link: '/datentransfer/importhistorie',
      },
      {
        title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.EXPORT',
        link: '/datentransfer/datenexport',
      },
      {
        title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.IMPORT_EXPORT',
        link: '/datentransfer',
        hideInNavigation: true,
        children: [
          {
            title: 'SIDENAV.NAVIGATION.DATA_TRANSFER.RPA_SETTINGS',
            link: '/rpa-einstellungen',
          },
        ],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.SHIPPING_ORGANIZATION.TITLE',
    visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.SHIPPING_ORGANIZATION.RECIPIENT',
        link: '/versandorganisation/empfaengerliste',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
      },
      {
        title: 'SIDENAV.NAVIGATION.SHIPPING_ORGANIZATION.LETTER',
        link: '/versandorganisation/briefe/serienbriefdaten',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
      },
      {
        title: 'SIDENAV.NAVIGATION.SHIPPING_ORGANIZATION.E_POST',
        link: '/versandorganisation/adressdaten-epostfach',
        visibleForRoles: [MfUserRolesEnum.DATA_MANAGER],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.RECIPIENT_LIST.TITLE',
    visibleForRoles: [
      MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
      MfUserRolesEnum.PARTNER_BANK_EMPLOYEE,
    ],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.RECIPIENT_LIST.CHECK.SINGLE',
        link: '/partner/adressdaten/pruefen/einzelkampagnen',
        visibleForRoles: [MfUserRolesEnum.PARTNER_MARKETING_MANAGER],
      },
      {
        title: 'SIDENAV.NAVIGATION.RECIPIENT_LIST.CHECK.PERMANENT',
        link: '/partner/adressdaten/pruefen/dauerkampagnen',
        visibleForRoles: [MfUserRolesEnum.PARTNER_MARKETING_MANAGER],
      },
      {
        title: 'SIDENAV.NAVIGATION.RECIPIENT_LIST.RELEASE.SINGLE',
        link: '/partner/adressdaten/freigeben/einzelkampagnen',
        visibleForRoles: [MfUserRolesEnum.PARTNER_BANK_EMPLOYEE],
      },
      {
        title: 'SIDENAV.NAVIGATION.RECIPIENT_LIST.RELEASE.PERMANENT',
        link: '/partner/adressdaten/freigeben/dauerkampagnen',
        visibleForRoles: [MfUserRolesEnum.PARTNER_BANK_EMPLOYEE],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.BILLING',
        link: '/abrechnungen',
        visibleForRoles: [
          MfUserRolesEnum.CAMPAIGN_MANAGER,
          MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_DATA.TITLE',
        link: '/bank-administration/bankdaten',
        visibleForRoles: [MfUserRolesEnum.BANK_ADMIN],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.TITLE',
        link: '/partner/administration/bankenverwaltung',
        visibleForRoles: [MfUserRolesEnum.PARTNER_ADMIN],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.TITLE',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.USER_ADMINISTRATION.TITLE',
        link: '/benutzerverwaltung',
        visibleForRoles: [
          MfUserRolesEnum.BANK_ADMIN,
          MfUserRolesEnum.PARTNER_ADMIN,
          MfUserRolesEnum.ADMIN_USER_MANAGEMENT,
        ],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.USER_ADMINISTRATION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.USER_ADMINISTRATION.UPSERT_EDIT',
            link: '/bearbeiten/',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.ORGANIZATION.CONSULTANT.TITLE',
        link: '/organisation/berater',
        visibleForRoles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.DATA_MANAGER],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.ORGANIZATION.CONSULTANT.UPSERT_NEW',
            link: '/erstellen',
          },
          {
            title: 'SIDENAV.NAVIGATION.ORGANIZATION.CONSULTANT.UPSERT_EDIT',
            link: '/bearbeiten',
          },
          {
            title: 'SIDENAV.NAVIGATION.ORGANIZATION.CONSULTANT.WEBSITE_IMPORT',
            link: '/import-bankwebseite',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.LETTER_MANAGEMENT.LETTER_TEMPLATE.TITLE',
        link: '/briefverwaltung/briefvorlagen',
        visibleForRoles: [
          MfUserRolesEnum.CAMPAIGN_MANAGER,
          MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
        ],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.LETTER_MANAGEMENT.LETTER_TEMPLATE.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.LETTER_MANAGEMENT.LETTER_TEMPLATE.UPSERT_EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.PRODUCT.TITLE',
        link: '/produkte',
        visibleForRoles: [MfUserRolesEnum.BANK_ADMIN],
        requiredBankFeatures: [BankFeatures.PRODUCTS],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.PRODUCT.NEW',
            link: '/erstellen',
          },
          {
            title: 'SIDENAV.NAVIGATION.PRODUCT.EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.ORGANIZATION.BRANCH_OFFICE.TITLE',
        link: '/organisation/zweigstellen',
        visibleForRoles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.DATA_MANAGER],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.ORGANIZATION.BRANCH_OFFICE.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.ORGANIZATION.BRANCH_OFFICE.UPSERT_EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.TITLE',
        link: '/emni-administration/bankenverwaltung',
        visibleForRoles: [MfUserRolesEnum.ADMIN_BANK_MANAGEMENT],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.UPSERT_EDIT',
            link: '/bearbeiten',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.BILLING',
            link: '/abrechnungen',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.FEATURES',
            link: '/funktionen',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.BANK_ADMINISTRATION.CONTRACTS',
            link: '/vertraege',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.EMAIL_TEMPLATES.TITLE',
        link: '/emni-administration/mailvorlagen',
        visibleForRoles: [MfUserRolesEnum.ADMIN_EMAIL_TEMPLATES],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.EMAIL_TEMPLATES.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.EMAIL_TEMPLATES.UPSERT_EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.DOCUMENT_FORM_SCAN.TITLE',
        link: '/marktfolge',
        visibleForRoles: [MfUserRolesEnum.ADMIN_BACK_OFFICE],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.MEDIA_LIBRARY',
        link: '/emni-administration/medienbibliothek',
        visibleForRoles: [MfUserRolesEnum.ADMIN_MEDIA_LIBRARY],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.SUPPORT_DOCUMENTS.TITLE',
        link: '/emni-administration/dokumentenverwaltung',
        visibleForRoles: [MfUserRolesEnum.ADMIN_DOCUMENT_MANAGEMENT],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.SUPPORT_DOCUMENTS.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.SUPPORT_DOCUMENTS.UPSERT_EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.SERVICE_PROVIDERS.TITLE',
        link: '/emni-administration/externe-dienstleister',
        visibleForRoles: [MfUserRolesEnum.ADMIN_MAILJET, MfUserRolesEnum.ADMIN_DEPARTMENT_X],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.IDA_VERSION',
        link: '/emni-administration/ida-version',
        visibleForRoles: [MfUserRolesEnum.ADMIN_IDA],
      },
      {
        title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.INSTRUCTION_VIDEO.TITLE',
        link: '/anleitungsvideos',
        visibleForRoles: [MfUserRolesEnum.ADMIN_VIDEO_MANAGEMENT],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.INSTRUCTION_VIDEO.UPSERT_NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.EMNI_ADMINISTRATION.INSTRUCTION_VIDEO.UPSERT_EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.CONTRACT_TEMPLATE.LIST',
        link: '/vertragsvorlagen',
        visibleForRoles: [MfUserRolesEnum.ADMIN_CONTRACT_MANAGEMENT],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.CONTRACT_TEMPLATE.UPSERT_NEW',
            link: '/neu',
          },
        ],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.REVISION.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.REVISION.LOG',
        link: '/revision',
        visibleForRoles: [MfUserRolesEnum.REVISION],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.CONTRACT.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.CONTRACT.LIST',
        link: '/vertraege',
        visibleForRoles: [MfUserRolesEnum.CONTRACT_MANAGER],
      },
    ],
  },
];
