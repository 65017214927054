import { Component, Input } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'mf-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class MfLoaderComponent {
  @Input() label?: string;
}
