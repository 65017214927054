<ng-container *transloco="let t">
  @if (showNavigation) {
    @for (item of sidenavItems; track item.title) {
      <div class="group">
        <div class="header">
          {{ t(item.title) }}
        </div>

        @for (child of item.children; track child.link) {
          @if (!child.hidden) {
            <a class="item" [class.active]="child.active" [routerLink]="[child.link]">
              {{ t(child.title) }}
            </a>
          }
        }
      </div>
    }

    @if (showToolboxItem) {
      <a class="item with-icon" routerLink="/werkzeugkasten" routerLinkActive="active">
        <mat-icon svgIcon="toolbox" />
        <span>{{ t('SIDENAV.NAVIGATION.TESTING') }}</span>
      </a>
    }
  }
</ng-container>
