import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfAppArea } from '@shared/navigation/navigation.types';

@Component({
  selector: 'mf-layout-sidenav-tabs',
  templateUrl: './tabs.component.html',
  styles: `
    .link-item {
      @apply border-b p-3 text-center;

      &.active {
        @apply border-[#0B6574] font-bold;
      }

      &.inactive {
        @apply border-[#CBCCCE];
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, RouterLink, RouterLinkActive, NgClass, MatLegacyChipsModule],
})
export class MfLayoutSidenavTabsComponent {
  route = inject(ActivatedRoute);

  get isMarketplace() {
    return this.route.snapshot.data['area'] === MfAppArea.MARKETPLACE;
  }

  get isNew() {
    return new Date() < new Date('2025-03-01T00:00:00+02:00');
  }
}
