<ng-container *transloco="let t; scope: 'dataTransfer'">
  @if (
    !isWidget || (isWidget && status !== MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION)
  ) {
    <mf-data-manager-card
      [icon]="isWidget && status !== MfDataManagerExportDownloaderStatusEnum.ERROR ? null : icon"
      [iconAnimated]="iconAnimated"
      [iconClass]="iconClass"
      [isDeactivated]="status === MfDataManagerExportDownloaderStatusEnum.EMPTY"
      [isWidget]="isWidget"
    >
      <form
        class="w-full"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        mfFocusFirstInvalidField
        novalidate
      >
        @if (isWidget) {
          @if (status === MfDataManagerExportDownloaderStatusEnum.DECRYPT) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.DECRYPT') }}
            </p>
          }
          @if (status === MfDataManagerExportDownloaderStatusEnum.GENERATE) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.GENERATE', { minutes: minutesToGenerate }) }}
            </p>
          }
          @if (status === MfDataManagerExportDownloaderStatusEnum.COMPRESS) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.COMPRESS') }}
            </p>
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.ERROR) {
            <p class="font-bold">
              <mat-error>{{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.HEADLINE') }}</mat-error>
            </p>
            <p class="mb-4">
              <mat-error>
                {{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.WIDGET_INFO_PART_1') }}
                <a class="link" routerLink="/datentransfer/datenexport">
                  {{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.WIDGET_INFO_PART_2') }}
                </a>
                {{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.WIDGET_INFO_PART_3') }}
              </mat-error>
            </p>

            <div class="form-container-full">
              <ng-container *ngTemplateOutlet="pseudonymizationPasswordField" />
            </div>

            <ng-container *ngTemplateOutlet="errorButtons" />
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR) {
            <p class="font-bold">
              <mat-error>
                {{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.DOWNLOAD.HEADLINE') }}
              </mat-error>
            </p>
            <p>
              <mat-error>{{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.DOWNLOAD.INFO') }}</mat-error>
            </p>

            <ng-container *ngTemplateOutlet="downloadErrorButtons" />
          }
        } @else {
          @if (status === MfDataManagerExportDownloaderStatusEnum.INITIALIZE) {
            <div class="w-full">
              <mf-loader [label]="t('dataTransfer.EXPORT.INITIALIZE.LOADER_LABEL')" />
            </div>
          }

          @if (
            status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION ||
            status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION
          ) {
            <p class="font-bold" [ngClass]="{ 'mb-4': !lastRefresh }">
              {{ t('dataTransfer.EXPORT.DOWNLOADER.HEADLINE') }}
            </p>
            @if (lastRefresh) {
              <p class="mb-4">
                {{
                  t('dataTransfer.EXPORT.DOWNLOADER.LAST_REFRESH', {
                    date: (lastRefresh | date: 'mediumDate'),
                    time: (lastRefresh | date: 'shortTime'),
                  })
                }}
              </p>
            }
          }

          @if (
            status === MfDataManagerExportDownloaderStatusEnum.DECRYPT ||
            status === MfDataManagerExportDownloaderStatusEnum.GENERATE ||
            status === MfDataManagerExportDownloaderStatusEnum.COMPRESS
          ) {
            <p class="font-bold">
              {{ t('dataTransfer.EXPORT.DOWNLOADER.PREPARE_DOWNLOAD') }}
            </p>
          }
          @if (status === MfDataManagerExportDownloaderStatusEnum.DECRYPT) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.DECRYPT') }}
            </p>
          }
          @if (status === MfDataManagerExportDownloaderStatusEnum.GENERATE) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.GENERATE', { minutes: minutesToGenerate }) }}
            </p>
          }
          @if (status === MfDataManagerExportDownloaderStatusEnum.COMPRESS) {
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.COMPRESS') }}
            </p>
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.ERROR) {
            <p class="font-bold">
              <mat-error>{{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.HEADLINE') }}</mat-error>
            </p>
            <p class="mb-4">
              <mat-error>{{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.INFO') }}</mat-error>
            </p>
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR) {
            <p class="font-bold">
              <mat-error>
                {{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.DOWNLOAD.HEADLINE') }}
              </mat-error>
            </p>
            <p class="mb-4">
              <mat-error>{{ t('dataTransfer.EXPORT.DOWNLOADER.ERROR.DOWNLOAD.INFO') }}</mat-error>
            </p>
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.EMPTY) {
            <p class="font-bold">
              {{ t('dataTransfer.EXPORT.DOWNLOADER.EMPTY.HEADLINE') }}
            </p>
            <p>
              {{ t('dataTransfer.EXPORT.DOWNLOADER.EMPTY.INFO') }}
            </p>
          }

          @if (
            status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION ||
            status === MfDataManagerExportDownloaderStatusEnum.ERROR
          ) {
            <div class="form-container-lg mb-4">
              <ng-container *ngTemplateOutlet="pseudonymizationPasswordField" />
            </div>
          }

          @if (
            status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION ||
            status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION ||
            status === MfDataManagerExportDownloaderStatusEnum.DECRYPT ||
            status === MfDataManagerExportDownloaderStatusEnum.GENERATE ||
            status === MfDataManagerExportDownloaderStatusEnum.COMPRESS
          ) {
            <p class="mb-4">
              {{ t('dataTransfer.EXPORT.DOWNLOADER.INFO') }}
            </p>
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION) {
            <mf-primary-button
              [label]="t('dataTransfer.EXPORT.DOWNLOADER.EXPORT_BUTTON')"
              type="submit"
            />
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION) {
            <mf-primary-button
              [label]="t('dataTransfer.EXPORT.DOWNLOADER.DOWNLOAD_BUTTON')"
              (clicked)="downloadFile()"
            />
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.ERROR) {
            <ng-container *ngTemplateOutlet="errorButtons" />
          }

          @if (status === MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR) {
            <ng-container *ngTemplateOutlet="downloadErrorButtons" />
          }
        }
      </form>
    </mf-data-manager-card>
  }

  <ng-template #pseudonymizationPasswordField>
    <mf-form-input
      [formGroup]="form"
      [label]="t('SHARED.FORMS.LABELS.PSEUDONYMIZATION_PASSWORD')"
      name="pseudonymizationPassword"
      noBottomOffset
      passwordHasToggleButton
      type="password"
    />
  </ng-template>

  <ng-template #downloadErrorButtons>
    <div class="flex gap-2" [ngClass]="isWidget ? 'flex-col' : 'flex-row'">
      <mf-primary-button
        [label]="t('dataTransfer.EXPORT.DOWNLOADER.ERROR.DOWNLOAD.REGENERATE_BUTTON')"
        (clicked)="regenerate()"
        autoStretch
      />
    </div>
  </ng-template>

  <ng-template #errorButtons>
    <div class="flex gap-2" [ngClass]="isWidget ? 'flex-col' : 'flex-row'">
      <mf-primary-button
        [label]="t('dataTransfer.EXPORT.DOWNLOADER.ERROR.EXPORT_BUTTON')"
        (click)="onSubmit()"
        autoStretch
      />
      <mf-secondary-button
        [label]="t('dataTransfer.EXPORT.DOWNLOADER.ERROR.CANCEL_BUTTON')"
        (click)="cancel()"
        autoStretch
      />
    </div>
  </ng-template>
</ng-container>
