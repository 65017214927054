<ng-container *transloco="let t">
  @if (!hasWarning) {
    <div
      class="flex items-center justify-between space-x-1"
      [ngClass]="{
        'has-success': hintLabel && showValidationSuccess && field?.valid && field?.touched,
      }"
    >
      @if (hintLabel || (!isRequired && showOptionalHint)) {
        <div class="flex items-center space-x-1">
          @if (field?.valid && field?.touched && !showOptionalHint && showValidationSuccess) {
            <mat-icon svgIcon="success" />
          }
          @if ((!field?.touched || !showValidationSuccess) && !showOptionalHint && !hideInfoIcon) {
            <mat-icon svgIcon="info" />
          }
          <span>
            @if (!isRequired && showOptionalHint) {
              {{ t('SHARED.FORMS.OPTIONAL') }}{{ hintLabel ? ':' : '' }}
            }
            <span [innerHTML]="hintLabel"></span>
          </span>
        </div>
      }
      @if (maxLengthHint) {
        <mf-form-util-length-handler
          class="max-length-hint"
          [field]="field"
          [length]="length"
          [maxLength]="maxLengthHint"
        />
      }
    </div>
  } @else {
    <div class="warning flex items-center space-x-1">
      <mat-icon svgIcon="warning" />
      <span>
        {{ warningLabel }}
      </span>
      @if (warningLabel) {
        <mf-form-util-length-handler
          class="max-length-hint"
          [field]="field"
          [length]="length"
          [maxLength]="maxLengthHint"
        />
      }
    </div>
  }
</ng-container>
