import { Component } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import { MfAbstractDialogInjected } from '@shared/dialog/service/dialog.interface';

@Component({
  selector: 'mf-alert-dialog',
  standalone: true,
  imports: [MaterialModule, MfTertiaryButtonComponent, TranslocoDirective],
  templateUrl: './alert-dialog.component.html',
})
export class MfAlertDialogComponent extends MfAbstractDialogInjected<
  { title: string; message: string },
  undefined
> {}
