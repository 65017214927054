<ng-container *transloco="let t">
  @if (labels) {
    <div class="flex flex-col">
      @for (label of labels; track label) {
        <div
          class="validator mb-1 flex"
          [ngClass]="field?.dirty ? (getValidatorError(label) ? 'invalid' : 'valid') : null"
        >
          @if (field?.dirty) {
            <mat-icon [svgIcon]="getValidatorError(label) ? 'close' : 'success'" />
          }
          <span>{{ t(label) }}</span>
        </div>
      }
    </div>
  }
</ng-container>
